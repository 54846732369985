import { LoanDurationType, LoanProductType } from "../../hooks/api/types";
import { LoanProductConfig } from "../dpConfig/types";

type CommonLoanInputParams = {
  amount: number;
  duration: number;
  durationType: LoanDurationType;
  interestRate: number;
};

export type LoanProductInvoiceConfig = {
  discount: {
    min: number;
    max: number;
    initial: number;
    unit: string;
  };
};

export type UserLoanCalculatorConstraintsConfig = {
  units?: {
    currency?: string;
    tenure: string;
  };
  buckets: Array<{
    range: {
      amount: {
        from: number;
        to: number;
      };
    };
    constraints: {
      tenure:
        | {
            from: number;
            to: number;
          } // range of tenure
        | number; // single tenure value
      discountRate?: number;
      interestRate: number;
    };
  }>;
};

type FixedTermLoanParams = CommonLoanInputParams;
export type RevenueBasedLoanParams = CommonLoanInputParams & {
  salesStatements: RevenueBasedLoanSalesStatementParams[];
};

type RevenueBasedLoanSalesStatementParams = {
  documentId: string;
};

export type InvoiceDiscountingLoanInvoiceParams = CommonLoanInputParams & {
  invoiceId: string;
  discountRate: number;
  invoiceDueDate: Date;
  invoiceIssuedDate: Date;
  invoiceNo: string;
};

type InvoiceDiscountingLoan = {
  invoices: InvoiceDiscountingLoanInvoiceParams[];
};

export type PayableFinancingLoanInvoiceParams = CommonLoanInputParams & {
  invoiceId: string;
  discountRate: number;
  invoiceDueDate: Date;
  invoiceIssuedDate: Date;
  invoiceNo: string;
};

type PayableFinancingLoan = {
  invoices: PayableFinancingLoanInvoiceParams[];
};

type BuyNowPayLaterLoan = CommonLoanInputParams & {
  salesInvoices: BuyNowPayLaterLoanSalesInvoiceParams[];
};

type BuyNowPayLaterLoanSalesInvoiceParams = {
  invoiceId: string;
};

type FixedTermLoanReducerAction = {
  type: "SET_FIXED_TERM_LOAN";
  payload: FixedTermLoanParams;
};

type InvoiceDiscountingLoanReducerAction = {
  type: "SET_INVOICE_DISCOUNTING_LOAN";
  payload: InvoiceDiscountingLoan;
};

type PayableFinancingLoanReducerAction = {
  type: "SET_PAYABLE_FINANCING_LOAN";
  payload: PayableFinancingLoan;
};

type RevenueBasedLoanReducerAction = {
  type: "SET_REVENUE_BASED_LOAN";
  payload: RevenueBasedLoanParams;
};

type BuyNowPayLaterLoanReducerAction = {
  type: "SET_BNPL_LOAN";
  payload: BuyNowPayLaterLoan;
};

type SetConstraintsReducerAction = {
  type: "SET_CONSTRAINTS";
  payload: LoanCalculatorState["constraints"];
};

type SetRevenueBasedLoanConstraints = {
  type: "SET_REVENUE_BASED_LOAN_CONSTRAINTS";
  payload: LoanCalculatorState["constraints"]["revenueBasedLoan"];
};

type SetLoadingAction = {
  type: "SET_LOADING";
  payload: boolean;
};

export type LoanCalculatorState = {
  loading: boolean;
  type?: LoanProductType;
  fixedTermLoan: FixedTermLoanParams;
  invoiceDiscountingLoan: InvoiceDiscountingLoan;
  payableFinancingLoan: PayableFinancingLoan;
  revenueBasedLoan: RevenueBasedLoanParams;
  buyNowPayLaterLoan: BuyNowPayLaterLoan;
  constraints: {
    fixedTermLoan: LoanProductConfig;
    invoiceDiscountingLoan: LoanProductConfig & LoanProductInvoiceConfig;
    payableFinancingLoan: LoanProductConfig & LoanProductInvoiceConfig;
    revenueBasedLoan: LoanProductConfig;
    buyNowPayLaterLoan: LoanProductConfig;
    userConstraintsConfig?: UserLoanCalculatorConstraintsConfig | null;
  };
};

export type LoanCalculatorActions = {
  setFixedTermLoan: (loan: FixedTermLoanParams) => void;
  setInvoiceDiscountingLoan: (loan: InvoiceDiscountingLoan) => void;
  setPayableFinancingLoan: (loan: PayableFinancingLoan) => void;
  setRevenueBasedLoan: (loan: RevenueBasedLoanParams) => void;
  setBuyNowPayLaterLoan: (loan: BuyNowPayLaterLoan) => void;
  setLoading: (loading: boolean) => void;
  setRevenueBasedLoanConstraints: (constraints: LoanProductConfig) => void;
};

export type LoanCalculatorContextState = {
  state: LoanCalculatorState;
  actions: LoanCalculatorActions;
};

export type LoanCalculatorReducerAction =
  | FixedTermLoanReducerAction
  | RevenueBasedLoanReducerAction
  | InvoiceDiscountingLoanReducerAction
  | PayableFinancingLoanReducerAction
  | SetConstraintsReducerAction
  | SetLoadingAction
  | BuyNowPayLaterLoanReducerAction
  | SetRevenueBasedLoanConstraints;

export const LOAN_CALCULATOR_INITIAL_VALUES: LoanCalculatorContextState = {
  state: {
    loading: true,
    type: undefined,
    fixedTermLoan: {
      amount: 100_000,
      duration: 12,
      durationType: LoanDurationType.MONTHS,
      interestRate: 0.18,
    },
    revenueBasedLoan: {
      amount: 100_000,
      duration: 12,
      durationType: LoanDurationType.MONTHS,
      interestRate: 0.2,
      salesStatements: [],
    },
    invoiceDiscountingLoan: {
      invoices: [],
    },
    payableFinancingLoan: {
      invoices: [],
    },
    buyNowPayLaterLoan: {
      amount: 100_000,
      duration: 3,
      durationType: LoanDurationType.MONTHS,
      interestRate: 0.2,
      salesInvoices: [],
    },
    constraints: {
      fixedTermLoan: {
        amount: {
          min: 20_000,
          max: 3_000_000,
          initial: 100_000,
          unit: "AED",
        },
        duration: {
          min: 3,
          max: 12,
          initial: 6,
          unit: "months",
        },
        interestRate: 0.18,
      },
      revenueBasedLoan: {
        amount: {
          min: 20_000,
          max: 1_000_000,
          initial: 100_000,
          unit: "AED",
        },
        duration: {
          min: 3,
          max: 12,
          initial: 6,
          unit: "months",
          isRange: true, // if false then min and max set to same value (the single duration)
        },
        interestRate: 0.18,
      },
      buyNowPayLaterLoan: {
        amount: {
          min: 10_000,
          max: 500_000,
          initial: 10_000,
          unit: "AED",
        },
        duration: {
          min: 3,
          max: 12,
          initial: 6,
          unit: "months",
        },
        interestRate: 0.2,
      },
      invoiceDiscountingLoan: {
        amount: {
          min: 20_000,
          max: 3_000_000,
          initial: 100_000,
          unit: "AED",
        },
        duration: {
          min: 15,
          max: 120,
          initial: 30,
          unit: "days",
        },
        interestRate: 0.18,
        discount: {
          min: 20,
          max: 90,
          initial: 90,
          unit: "%",
        },
      },
      payableFinancingLoan: {
        amount: {
          min: 20_000,
          max: 3_000_000,
          initial: 100_000,
          unit: "AED",
        },
        duration: {
          min: 30,
          max: 120,
          initial: 30,
          unit: "days",
        },
        discount: {
          min: 100,
          max: 100,
          initial: 100,
          unit: "%",
        },
        interestRate: 0.18,
      },
      userConstraintsConfig: null,
    },
  },
  actions: {
    setFixedTermLoan: () => {},
    setInvoiceDiscountingLoan: () => {},
    setPayableFinancingLoan: () => {},
    setRevenueBasedLoan: () => {},
    setBuyNowPayLaterLoan: () => {},
    setLoading: () => {},
    setRevenueBasedLoanConstraints: () => {},
  },
};
