import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { DateTime } from "luxon";
import {
  useCancelActiveLoanApplication,
  useCreateLoanApplication,
  useGetActiveLoanApplication,
  useUpdateLoanApplication,
} from "../../hooks";
import {
  CompanyShareholderType,
  CreateLoanApplicationRequest,
  LoanApplicationStage,
  LoanDurationType,
  LoanProductType,
  ShareholderEntityType,
  ShareholderKYCApplicationStatus,
} from "../../hooks/api/types";
import { ShareholderFields } from "../../views/LoanApplicationSteps/OwnersInformation/service";
import { ExistingLoanApplicationModal } from "../../views/LoanApplicationSteps/Onboarding";
import { ButtonLoaderIcon } from "../../assets/icons";
import { useSDKContext } from "../sdk";
import { LOCALSTORAGE_KEYS } from "../../constants";
import { usePostHog } from "posthog-js/react";
import { CustomFile } from "../../views/LoanApplicationSteps/FinancialInformation/service";

export interface IndustryType {
  id: string;
  name: string;
}

export interface LicensingAuthorityType {
  id: string;
  name: string;
}

type FixedTermLoan = {
  amount: number;
  duration: number;
  interestRate: number;
  durationType: LoanDurationType;
};

type RevenueBasedLoan = {
  amount: number;
  duration: number;
  interestRate: number;
  durationType: LoanDurationType;
  salesStatements: SalesStatement[];
};

type BuyNowPayLaterLoan = {
  amount: number;
  duration: number;
  interestRate: number;
  durationType: LoanDurationType;
  salesInvoices: SalesInvoice[];
};

type SalesInvoice = {
  invoiceId: string;
};

type SalesStatement = {
  documentId: string;
};

export type InvoiceDiscountingInvoice = {
  amount: number; // this is invoice amount
  discountRate: number;
  duration: number;
  durationType: LoanDurationType;
  interestRate: number;
  invoiceId: string;
  invoiceDueDate: Date;
  invoiceIssuedDate: Date;
  invoiceNo: string;
};

export type PayableFinancingInvoice = {
  amount: number; // this is invoice amount
  discountRate: number;
  duration: number;
  durationType: LoanDurationType;
  interestRate: number;
  invoiceId: string;
  invoiceDueDate: Date;
  invoiceIssuedDate: Date;
  invoiceNo: string;
};

export type Applicant = {
  firstName: string;
  lastName: string;
  email: string;
  isStakeholder: boolean;
  hasPOA: boolean;
  kycApplication?: Record<string, any> | null;
  id?: string;
  countryCode?: string;
  localNumber?: string;
};

export type KycApplication = {
  id: string;
  createdAt: string; // Date format in ISO string
  loanApplicationId: string;
  companyId: string;
  shareholderId: string;
  kycApplicationId: string | null; // nullable
  status: ShareholderKYCApplicationStatus;
};

export type IndividualShareholder = {
  __entityType: ShareholderEntityType.INDIVIDUAL;
  id?: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  localNumber: string;
  email: string;
  isStakeholder: boolean;
  hasPOA: boolean;
  type: CompanyShareholderType;
  kycApplication?: KycApplication[] | [];
  latestKycApplication?: KycApplication | null;
  skipEidUpload?: boolean;
};

export type CompanyShareholder = {
  __entityType: ShareholderEntityType.COMPANY;
  id?: string;
  name: string;
  email: string;
  countryCode: string;
  localNumber: string;
  hasPOA: boolean;
  isStakeholder: boolean;
};

export enum OTPType {
  SMS = "SMS",
  EMAIL = "EMAIL",
}

export type LoanApplicationContextValues = {
  state: {
    loanReason: string;
    loading: boolean;
    updating: boolean;
    id: string;
    companyId: string;
    stage: LoanApplicationStage;
    countryCode: string;
    localNumber: string;
    otpVerified: boolean;
    otpType: OTPType;
    tradeLicenseNumber: string;
    licensingAuthority: LicensingAuthorityType | null;
    licensingAuthorityId: string;
    email: string;
    lastYearPaidTax: number;
    annualRevenue: number;
    taxRegistrationNumber: string;
    businessName: string;
    businessYearEstabilished: string;
    businessType: string;
    businessEntity: string;
    businessEmirate: string;
    businessArea: string;
    businessBuilding: string;
    businessStreet: string;
    businessFloor: string;
    businessUnit: string;
    industryType: IndustryType | null;
    industryTypeId: string;
    docTradeLicense: string;
    docMemorandumOfAssociation: string;
    docPowerOfAttorney: string;
    docBankStatements: string[];
    docBankStatementFiles: CustomFile[];
    docVatStatements: string[];
    docVatStatementFiles: CustomFile[];
    docGeneric: string[];
    docGenericFiles: CustomFile[];
    distributionPartnerId?: string;
    additionalDPMetadata: Record<string, string> | null;
    userId: string;
    firstName: string;
    lastName: string;
    shareholders: Array<ShareholderFields>;
    createdAt: string;
    leanCustomerId: string;
    leanApplicationId: string;
    financeInformationOption: "connect" | "upload" | null;
    loanType?: LoanProductType;
    fixedTermLoan: FixedTermLoan;
    revenueBasedLoan: RevenueBasedLoan;
    buyNowPayLaterLoan: BuyNowPayLaterLoan;
    invoices: InvoiceDiscountingInvoice[] | PayableFinancingInvoice[];
    invoiceFiles: File[];
    salesStatementFiles: File[];
    salesInvoiceFiles: File[];
    applicant: Applicant;
    individualShareholders: IndividualShareholder[];
    companyShareholders: CompanyShareholder[];
    sourceId: string | null;
    shareHolderPendingKYC: IndividualShareholder | null;
    additionalData: Record<string, any>;
  };
  actions: {
    update: (
      payload: Partial<LoanApplicationContextValues["state"]>,
      options?: {
        local?: boolean;
      }
    ) => Promise<any>;
    create: (payload: CreateLoanApplicationRequest) => Promise<any>;
    refetch: () => Promise<any>;
    reset: () => void;
  };
};

type LoanApplicationContextAction = {
  type: "UPDATE";
  payload: Partial<LoanApplicationContextValues["state"]>;
  options?: {
    local?: boolean;
  };
};

const INITIAL_STATE: LoanApplicationContextValues["state"] = {
  loanReason: "",
  loading: false,
  updating: false,
  id: "",
  companyId: "",
  stage: LoanApplicationStage.BRANDING,
  countryCode: "+971",
  localNumber: "",
  otpVerified: false,
  otpType: OTPType.SMS,
  tradeLicenseNumber: "",
  licensingAuthority: null,
  licensingAuthorityId: "",
  email: "",
  annualRevenue: 0,
  taxRegistrationNumber: "",
  lastYearPaidTax: 0,
  businessName: "",
  businessYearEstabilished: "",
  businessType: "",
  businessEntity: "",
  businessEmirate: "",
  businessArea: "",
  businessBuilding: "",
  businessStreet: "",
  businessFloor: "",
  businessUnit: "",
  industryType: null,
  industryTypeId: "",
  docTradeLicense: "",
  docMemorandumOfAssociation: "",
  docPowerOfAttorney: "",
  docBankStatements: [],
  docBankStatementFiles: [],
  docVatStatements: [],
  docVatStatementFiles: [],
  docGeneric: [],
  docGenericFiles: [],
  additionalDPMetadata: null,
  userId: "",
  firstName: "",
  lastName: "",
  shareholders: [],
  createdAt: "",
  leanCustomerId: "",
  leanApplicationId: "",
  financeInformationOption: null,
  loanType: undefined,
  fixedTermLoan: {
    amount: 0,
    duration: 0,
    interestRate: 0,
    durationType: LoanDurationType.MONTHS,
  },
  revenueBasedLoan: {
    amount: 0,
    duration: 0,
    interestRate: 0,
    durationType: LoanDurationType.MONTHS,
    salesStatements: [],
  },
  buyNowPayLaterLoan: {
    amount: 0,
    duration: 0,
    interestRate: 0,
    durationType: LoanDurationType.MONTHS,
    salesInvoices: [],
  },
  invoices: [],
  invoiceFiles: [],
  salesStatementFiles: [],
  salesInvoiceFiles: [],
  applicant: {
    firstName: "",
    lastName: "",
    email: "",
    isStakeholder: true,
    hasPOA: true,
    kycApplication: null,
  },
  individualShareholders: [],
  companyShareholders: [],
  sourceId: null,
  shareHolderPendingKYC: null,
  additionalData: {},
};

const LoanApplicationContext = createContext<LoanApplicationContextValues>({
  state: INITIAL_STATE,
  actions: {
    update: async () => {},
    create: async () => {},
    refetch: async () => {},
    reset: async () => {},
  },
});

const reducer = (
  state: LoanApplicationContextValues,
  action: LoanApplicationContextAction
): LoanApplicationContextValues => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        state: {
          ...state.state,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

function hasDocumentsToUpdate(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  return !!(
    data.docTradeLicense ||
    data.docMemorandumOfAssociation ||
    data.docPowerOfAttorney ||
    (data.docVatStatements && data.docVatStatements.length > 0) ||
    (data.docBankStatements && data.docBankStatements.length > 0) ||
    (data.docGeneric && data.docGeneric.length > 0)
  );
}

function hasUserToUpdate(data: Partial<LoanApplicationContextValues["state"]>) {
  return !!(
    data.firstName ??
    data.lastName ??
    data.email ??
    data.countryCode ??
    data.localNumber
  );
}

function hasApplicantToUpdate(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  if (!data.applicant) return false;

  return [
    "firstName",
    "lastName",
    "email",
    "isStakeholder",
    "hasPOA",
    "kycApplication",
  ].some((property) => Object.hasOwn(data?.applicant!, property));
}

function createCompanyObject(
  data: Partial<LoanApplicationContextValues["state"]>,
  shouldUpdateDocuments: boolean
) {
  return {
    ...(data.businessName && { name: data.businessName }),
    ...(data.email && { businessEmail: data.email }),
    ...(data.tradeLicenseNumber && { licenseNumber: data.tradeLicenseNumber }),
    ...(data.annualRevenue && { annualRevenue: data.annualRevenue }),
    ...(data.taxRegistrationNumber && {
      taxRegistrationNumber: data.taxRegistrationNumber,
    }),
    ...(data.lastYearPaidTax && { lastYearPaidTax: data.lastYearPaidTax }),
    ...(data.businessYearEstabilished && {
      estabilishedYear: data.businessYearEstabilished,
    }),
    ...(data.businessType && { type: data.businessType }),
    ...(data.businessEmirate && { officeEmirate: data.businessEmirate }),
    ...(data.businessArea && { officeArea: data.businessArea }),
    ...(data.businessBuilding && { officeBuilding: data.businessBuilding }),
    ...(data.businessStreet && { officeStreet: data.businessStreet }),
    ...(data.businessFloor && { officeFloor: data.businessFloor }),
    ...(data.businessUnit && { officeUnit: data.businessUnit }),
    ...(data.shareholders && { shareholders: data.shareholders }),
    ...(shouldUpdateDocuments && {
      documents: createDocumentsObject(data),
    }),
    ...(data.individualShareholders && {
      individualShareholders: data.individualShareholders,
    }),
    ...(data.companyShareholders && {
      companyShareholders: data.companyShareholders,
    }),
  };
}

function createDocumentsObject(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  return {
    ...(data.docTradeLicense && { tradeLicense: data.docTradeLicense }),
    ...(data.docMemorandumOfAssociation && {
      memorandumOfAssociation: data.docMemorandumOfAssociation,
    }),
    ...(data.docPowerOfAttorney && {
      powerOfAttorney: data.docPowerOfAttorney,
    }),
    ...(data.docVatStatements && {
      vatStatements: data.docVatStatements.filter(Boolean),
    }),
    ...(data.docBankStatements && {
      bankStatements: data.docBankStatements.filter(Boolean),
    }),
    ...(data.docGeneric && {
      genericDocuments: data.docGeneric.filter(Boolean),
    }),
  };
}

function createUserObject(
  data: Partial<LoanApplicationContextValues["state"]>,
  shouldUpdateUser: boolean
) {
  const user = {
    ...(data.firstName &&
      data.lastName && { name: `${data.firstName} ${data.lastName}` }),
    ...(data.email && { email: data.email }),
    ...(data.countryCode && { countryCode: data.countryCode }),
    ...(data.localNumber && { localNumber: data.localNumber }),
  };

  return shouldUpdateUser ? user : undefined;
}

function createFixedTermLoanObject(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  if (data.loanType === LoanProductType.FIXED_TERM) {
    return {
      amount: data.fixedTermLoan?.amount,
      duration: data.fixedTermLoan?.duration,
      interestRate: data.fixedTermLoan?.interestRate,
      durationType: data.fixedTermLoan?.durationType,
    };
  }

  return undefined;
}

function createRevenueBasedLoanObject(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  if (data.loanType === LoanProductType.REVENUE_BASED_FINANCING) {
    return {
      amount: data.revenueBasedLoan?.amount,
      duration: data.revenueBasedLoan?.duration,
      interestRate: data.revenueBasedLoan?.interestRate,
      durationType: data.revenueBasedLoan?.durationType,
      salesStatements: data.revenueBasedLoan?.salesStatements,
    };
  }

  return undefined;
}

function createBuyNowPayLaterLoanObject(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  if (data.loanType === LoanProductType.BNPL) {
    return {
      amount: data.buyNowPayLaterLoan?.amount,
      duration: data.buyNowPayLaterLoan?.duration,
      interestRate: data.buyNowPayLaterLoan?.interestRate,
      durationType: data.buyNowPayLaterLoan?.durationType,
      salesInvoices: data.buyNowPayLaterLoan?.salesInvoices,
    };
  }

  return undefined;
}

function createInvoiceDiscountingLoanObject(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  if (data.loanType === LoanProductType.INVOICE_DISCOUNTING) {
    return {
      invoices: data.invoices?.map((invoice) => ({
        amount: invoice.amount,
        discountRate: invoice.discountRate / 100,
        duration: invoice.duration,
        durationType: invoice.durationType,
        interestRate: invoice.interestRate,
        invoiceId: invoice.invoiceId,
        invoiceDueDate: invoice.invoiceDueDate
          ? DateTime.fromJSDate(invoice.invoiceDueDate).toISODate()
          : undefined,
        invoiceIssuedDate: invoice.invoiceIssuedDate
          ? DateTime.fromJSDate(invoice.invoiceIssuedDate).toISODate()
          : undefined,
        invoiceNo: invoice.invoiceNo,
      })),
    };
  }

  return undefined;
}

function createPayableFinancingLoanObject(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  if (data.loanType === LoanProductType.PAYABLE_FINANCING) {
    return {
      invoices: data.invoices?.map((invoice) => ({
        amount: invoice.amount,
        discountRate: invoice.discountRate / 100,
        duration: invoice.duration,
        durationType: invoice.durationType,
        interestRate: invoice.interestRate,
        invoiceId: invoice.invoiceId,
        invoiceDueDate: invoice.invoiceDueDate
          ? DateTime.fromJSDate(invoice.invoiceDueDate).toISODate()
          : undefined,
        invoiceIssuedDate: invoice.invoiceIssuedDate
          ? DateTime.fromJSDate(invoice.invoiceIssuedDate).toISODate()
          : undefined,
        invoiceNo: invoice.invoiceNo,
      })),
    };
  }

  return undefined;
}

function createApplicantObject(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  const { applicant } = data;

  return {
    id: applicant?.id,
    firstName: applicant?.firstName,
    lastName: applicant?.lastName,
    email: applicant?.email,
    isStakeholder:
      applicant?.isStakeholder !== undefined
        ? applicant?.isStakeholder
        : undefined,
    hasPOA: applicant?.hasPOA !== undefined ? applicant?.hasPOA : undefined,
    kycApplication: applicant?.kycApplication
      ? {
          status: applicant.kycApplication.status,
        }
      : undefined,
  };
}

function transformStateForAPIUpdate(
  data: Partial<LoanApplicationContextValues["state"]>
) {
  const shouldUpdateDocuments = hasDocumentsToUpdate(data);
  const shouldUpdateUser = hasUserToUpdate(data);

  const company = createCompanyObject(data, shouldUpdateDocuments);
  const user = createUserObject(data, shouldUpdateUser);
  const fixedTermLoan = createFixedTermLoanObject(data);
  const revenueBasedLoan = createRevenueBasedLoanObject(data);
  const buyNowPayLaterLoan = createBuyNowPayLaterLoanObject(data);
  const invoiceDiscountingLoan = createInvoiceDiscountingLoanObject(data);
  const payableFinancingLoan = createPayableFinancingLoanObject(data);
  const shouldUpdateApplicant = hasApplicantToUpdate(data);

  return {
    ...(data.stage && { stage: data.stage }),
    company,
    user,
    fixedTermLoan,
    revenueBasedLoan,
    buyNowPayLaterLoan,
    invoiceDiscountingLoan,
    payableFinancingLoan,
    ...(data.licensingAuthorityId && {
      licensingAuthorityId: data.licensingAuthorityId,
    }),
    ...(data.industryTypeId && { industryTypeId: data.industryTypeId }),
    ...(data.loanType && { loanType: data.loanType }),
    ...(data.loanReason && { reason: data.loanReason }),
    ...(data.additionalData && {
      additionalData: data.additionalData,
    }),
    ...(data.additionalDPMetadata && {
      additionalDPMetadata: data.additionalDPMetadata,
    }),
    ...(shouldUpdateApplicant && { applicant: createApplicantObject(data) }),
  };
}

function transformStateFromAPI(
  data: any
): Partial<LoanApplicationContextValues["state"]> {
  const company = data.company ?? {};
  const user = data.user ?? {};
  const shareholders = company.shareholders ?? [];

  const state: Partial<LoanApplicationContextValues["state"]> = {
    id: data.id,
    companyId: data.companyId,
    stage: data.stage,
    loanReason: data.reason ?? "",
    distributionPartnerId: data.distributionPartnerId,
    businessName: company.name,
    email: company.businessEmail ?? user.email,
    tradeLicenseNumber: company.licenseNumber,
    annualRevenue: company.annualRevenue ?? 0,
    taxRegistrationNumber: company.taxRegistrationNumber ?? "",
    licensingAuthority: data.licensingAuthority,
    licensingAuthorityId: data.licensingAuthorityId,
    businessYearEstabilished: company.estabilishedYear,
    businessType: company.type,
    businessEmirate: company.officeEmirate,
    businessArea: company.officeArea,
    businessBuilding: company.officeBuilding,
    businessStreet: company.officeStreet,
    businessFloor: company.officeFloor,
    businessUnit: company.officeUnit,
    lastYearPaidTax: company.lastYearPaidTax ?? 0,
    industryType: data.industryType,
    industryTypeId: data.industryTypeId,
    shareholders: shareholders.map((shareholder: any) => ({
      id: shareholder.id,
      firstName: shareholder.firstName,
      lastName: shareholder.lastName,
      email: shareholder.email,
      countryCode: shareholder.countryCode,
      localNumber: shareholder.localNumber,
      type: shareholder.type,
    })),
    docTradeLicense: company.docTradeLicense ? company.docTradeLicenseId : "",
    docMemorandumOfAssociation: company.docMemorandumOfAssociation
      ? company.docMemorandumOfAssociationId
      : "",
    docPowerOfAttorney: company.docPowerOfAttorney
      ? company.docPowerOfAttorneyId
      : "",
    userId: user.id,
    firstName: user.name?.split(" ")[0],
    lastName: user.name?.split(" ")[1] ?? "",
    kycApplicant: user.kycApplicant,
    countryCode: user.countryCode,
    localNumber: user.localNumber,
    createdAt: data.createdAt,
    leanCustomerId: data.leanCustomerId,
    leanApplicationId: data.leanApplicationId,
    docBankStatements: data.bankStatements?.length
      ? data.bankStatements
          .map((statement: any) => statement?.document?.id)
          .filter((id: any) => id !== undefined && id !== null)
      : [],
    docVatStatements: data.vatStatements?.length
      ? data.vatStatements
          .map((statement: any) => statement?.document?.id)
          .filter((id: any) => id !== undefined && id !== null)
      : [],
    docGeneric: data.genericDocuments.length
      ? data.genericDocuments
          .map((statement: any) => statement?.media?.id)
          .filter((id: any) => id !== undefined && id !== null)
      : [],
    ...(data.applicant && { applicant: data.applicant }),
    ...(data.loanType && { loanType: data.loanType }),
    ...(data.fixedTermLoan && {
      fixedTermLoan: {
        amount: data.fixedTermLoan.loanAmount,
        duration: data.fixedTermLoan.duration,
        interestRate: data.fixedTermLoan.interestRate,
        durationType: data.fixedTermLoan.durationType,
      },
    }),
    ...(data.revenueBasedLoan && {
      revenueBasedLoan: {
        amount: data.revenueBasedLoan.loanAmount,
        duration: data.revenueBasedLoan.duration,
        interestRate: data.revenueBasedLoan.interestRate,
        durationType: data.revenueBasedLoan.durationType,
        salesStatements: data.revenueBasedLoan.salesStatements,
      },
    }),
    ...(data.buyNowPayLaterLoan && {
      buyNowPayLaterLoan: {
        amount: data.buyNowPayLaterLoan.loanAmount,
        duration: data.buyNowPayLaterLoan.duration,
        interestRate: data.buyNowPayLaterLoan.interestRate,
        durationType: data.buyNowPayLaterLoan.durationType,
        salesInvoices: data.buyNowPayLaterLoan.salesInvoices,
      },
    }),
    ...(data.invoiceDiscountingLoan?.invoices && {
      invoices: data.invoiceDiscountingLoan.invoices.map((invoice: any) => ({
        amount: invoice.loanAmount,
        discountRate: invoice.discountRate * 100,
        duration: invoice.duration,
        durationType: invoice.durationType,
        interestRate: invoice.interestRate,
        invoiceId: invoice.invoiceId,
        invoiceDueDate: invoice.invoiceDueDate,
        invoiceIssuedDate: invoice.invoiceIssuedDate,
        invoiceNo: invoice.invoiceNumber,
      })),
    }),
    ...(data.payableFinancingLoan?.invoices && {
      invoices: data.payableFinancingLoan.invoices.map((invoice: any) => ({
        amount: invoice.loanAmount,
        discountRate: invoice.discountRate * 100,
        duration: invoice.duration,
        durationType: invoice.durationType,
        interestRate: invoice.interestRate,
        invoiceId: invoice.invoiceId,
        invoiceDueDate: invoice.invoiceDueDate,
        invoiceIssuedDate: invoice.invoiceIssuedDate,
        totalAmount: invoice.totalAmount,
        invoiceNo: invoice.invoiceNumber,
      })),
    }),
    individualShareholders: company.individualShareholders,
    companyShareholders: company.companyShareholders,
    sourceId: data.refId,
    additionalData: data.additionalData,
  };

  return {
    ...JSON.parse(JSON.stringify(state)),
    invoices: state.invoices?.map((invoice) => ({
      ...invoice,
      invoiceDueDate: invoice.invoiceDueDate
        ? DateTime.fromISO(invoice.invoiceDueDate as any).toJSDate()
        : undefined,
      invoiceIssuedDate: invoice.invoiceIssuedDate
        ? DateTime.fromISO(invoice.invoiceIssuedDate as any).toJSDate()
        : undefined,
    })),
  };
}

const FullPageLoader = () => {
  return (
    <div className="cx-w-full cx-h-screen cx-flex cx-justify-center cx-items-center cx-text-brand-primary-regular">
      <ButtonLoaderIcon className="cx-animate-spin cx-w-6 cx-h-6" />
    </div>
  );
};

export const LoanApplicationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const [refetching, setRefetching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { prefillingCompleted, prefilling, displayMode } = useSDKContext();
  const [state, dispatch] = useReducer(reducer, {
    state: INITIAL_STATE,
    actions: {
      update: async () => {},
      create: async () => {},
      refetch: async () => {},
      reset: async () => {},
    },
  });
  const posthog = usePostHog();

  const { mutateAsync: getActiveLoanApplication } =
    useGetActiveLoanApplication();

  const { mutateAsync: cancelLoanApplication } =
    useCancelActiveLoanApplication();

  const { mutateAsync: createAPI } = useCreateLoanApplication();
  const { mutateAsync: updateAPI } = useUpdateLoanApplication();

  useEffect(() => {
    if (prefillingCompleted) {
      setRefetching(true);
      refetchFn().finally(() => {
        setRefetching(false);
      });
    }
  }, [prefillingCompleted]);

  useEffect(() => {
    getActiveLoanApplication()
      .then((data) => {
        if (data) {
          dispatch({
            type: "UPDATE",
            payload: {
              ...transformStateFromAPI(data),
              loading: false,
            },
          });
          displayMode == "loan-application" && setModalOpen(true);
          posthog.identify(data.id, {
            loanApplicationId: data.id,
            distributionPartnerId: data.distributionPartnerId,
            distributionPartnerName: data.distributionPartner?.name,
            name: data?.user?.name,
            email: data?.user?.email,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [displayMode]);

  const actions = useMemo(() => {
    return {
      update: async (
        payload: Partial<LoanApplicationContextValues["state"]>,
        options?: {
          local?: boolean;
        }
      ) => {
        dispatch({ type: "UPDATE", payload: { updating: true } });
        try {
          if (!options?.local) {
            if (
              state.state.stage === LoanApplicationStage.REVIEW &&
              payload.stage !== LoanApplicationStage.SUCCESS
            ) {
              delete payload.stage;
            }
            const result = await updateAPI({
              ...transformStateForAPIUpdate(payload),
              id: state.state.id || (payload.id as string),
              ...(state.state.companyId && {
                companyId: state.state.companyId,
              }),
            });
            dispatch({
              type: "UPDATE",
              payload: {
                ...payload,
                companyId: result.companyId,
                userId: result.userId,
                industryType: result.industryType,
                licensingAuthority: result.licensingAuthority,
                ...(payload.stage ===
                  LoanApplicationStage.SHAREHOLDER_INFORMATION && {
                  applicant: result?.applicant,
                  individualShareholders:
                    result?.company?.individualShareholders,
                  companyShareholders: result?.company?.companyShareholders,
                }),
              },
            });
            return result;
          }
          dispatch({ type: "UPDATE", payload });
        } catch (err) {
          console.log("An Error Occurred: ", err);
          throw err;
        } finally {
          dispatch({ type: "UPDATE", payload: { updating: false } });
        }
      },
      create: async (payload: CreateLoanApplicationRequest) => {
        dispatch({ type: "UPDATE", payload: { updating: true } });
        try {
          const data = await createAPI(payload);
          localStorage.setItem(LOCALSTORAGE_KEYS.LOAN_APPLICATION_ID, data.id); // Just for debugging purpose
          dispatch({
            type: "UPDATE",
            payload: { id: data.id },
          });
          return data;
        } catch (err) {
          console.log("An Error Occurred: ", err);
          throw err;
        } finally {
          dispatch({ type: "UPDATE", payload: { updating: false } });
        }
      },
      refetch: async () => {
        return refetchFn();
      },
      reset: () => {
        dispatch({ type: "UPDATE", payload: INITIAL_STATE });
      },
    };
  }, [dispatch, state.state]);

  const contextValue = useMemo(() => {
    return { state: state.state, actions };
  }, [state.state, actions]);

  const refetchFn = async () => {
    const data = await getActiveLoanApplication();
    if (data) {
      dispatch({
        type: "UPDATE",
        payload: { ...transformStateFromAPI(data) },
      });
    }
    return data;
  };

  const showLoader = useMemo(
    () => loading || refetching || prefilling,
    [loading, refetching, prefilling]
  );

  return (
    <LoanApplicationContext.Provider value={contextValue}>
      {showLoader ? <FullPageLoader /> : children}
      <ExistingLoanApplicationModal
        isOpen={modalOpen}
        date={DateTime.fromISO(state.state.createdAt).toLocaleString(
          DateTime.DATE_SHORT
        )}
        onClose={() => setModalOpen(false)}
        onContinue={() => {
          setModalOpen(false);
        }}
        onNewApplication={() => {
          cancelLoanApplication()
            .then(() => {
              dispatch({ type: "UPDATE", payload: INITIAL_STATE });
              setModalOpen(false);
              posthog.reset(true);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      />
    </LoanApplicationContext.Provider>
  );
};

export const useLoanApplication = () => useContext(LoanApplicationContext);
