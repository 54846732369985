import {
  ArrowIcon,
  BookmarkIcon,
  ForwardIcon,
  TimerIcon,
} from "../../../assets/icons";
import { Divider, IconCard, InformationBox } from "../../../components";
import { useDpConfig } from "../../../contexts/dpConfig";
import { useStepper } from "../Stepper";
import { useEffect } from "react";

const InitialPage = () => {
  const { nextStep } = useStepper();
  const {
    state: { uiLocalizationData },
  } = useDpConfig();

  const iconCardItems = [
    {
      title: "Quick application",
      subtitle: "Just 10 minutes to apply",
      icon: <TimerIcon className={"cx-text-text-brand"} />,
    },
    {
      title: "Fast pre-approval",
      subtitle: uiLocalizationData.introPage.approvalBox.subtitle,
      icon: <BookmarkIcon className={"cx-text-text-brand"} />,
    },
  ];

  return (
    <div className="cx-max-w-[960px] cx-px-2 cx-w-full cx-flex cx-flex-col cx-justify-items-start cx-gap-[5rem]">
      <div className={"cx-flex cx-flex-col cx-gap-y-10"}>
        <div>
          <h2
            className={
              "cx-font-normal cx-text-3xl cx-text-neutral-darker cx-leading-[40px]"
            }
          >
            {uiLocalizationData.introPage.headline.split(",")[0] + ","}
          </h2>
          <h2 className={"cx-font-normal cx-text-3xl"}>
            {uiLocalizationData.introPage.headline.split(",")[1].trim()}
          </h2>
        </div>

        <div
          className={
            "cx-flex md:cx-flex-row cx-flex-col cx-justify-between cx-gap-6"
          }
        >
          {iconCardItems.map((item) => (
            <div className={"cx-w-full"} key={item.title}>
              <IconCard
                title={item.title}
                subtitle={item.subtitle}
                icon={item.icon}
              />
            </div>
          ))}

          <div className={"cx-w-full"}>
            <IconCard
              title={uiLocalizationData.introPage.applyBox}
              onClick={nextStep}
              icon={<ArrowIcon className={"cx-animate-wiggle cx-text-white"} />}
            />
          </div>
        </div>
      </div>

      <Divider />

      <div
        className={
          "cx-flex cx-justify-between cx-gap-10 md:cx-flex-row cx-flex-col"
        }
      >
        <div className={"cx-basis-1/2 cx-gap-6 cx-flex cx-flex-col"}>
          <p className={"cx-font-normal cx-text-xl"}>Required documents</p>
          <div>
            <InformationBox
              information={uiLocalizationData.introPage.requiredDocuments}
            />
          </div>
        </div>
        <div className={"cx-basis-1/2 cx-gap-6 cx-flex cx-flex-col"}>
          <p className={"cx-font-normal cx-text-xl"}>How it works</p>
          <div>
            <InformationBox
              information={uiLocalizationData.introPage.howItWorks}
            />
          </div>
        </div>
      </div>

      <Divider />

      <div className={"cx-flex cx-flex-col cx-items-center"}>
        <p className={"cx-font-normal cx-text-xl"}> Still need help?</p>
        <p className={"cx-font-normal cx-text-base"}>
          Reach us at{" "}
          <a
            className={"cx-text-primary"}
            href={"mailto:help@crediblex.io"}
            target={"_blank"}
          >
            help@crediblex.io
          </a>
        </p>
      </div>

      <div className={"cx-h-0"}></div>
    </div>
  );
};

export default InitialPage;
