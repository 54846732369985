import { FormikProps } from "formik";
import {
  CardList,
  Dropzone,
  InputField,
  StepNextButton,
  StepPrevButton,
  DatePicker,
} from "../../components";
import { ArrowLeftIcon, DocumentIcon, SuccessIcon } from "../../assets/icons";
import { CardVariantType } from "../../components/CardList/CardList";
import { UploadInvoiceFields } from "./service";

interface UploadInvoiceFormProps {
  formik: FormikProps<UploadInvoiceFields>;
  handleUpload: (file: File) => void;
  uploading: boolean;
  repayableByDP?: boolean;
  previousStep?: () => void;
  onReviewScreen: boolean;
}

const UploadInvoiceForm: React.FC<UploadInvoiceFormProps> = ({
  formik,
  handleUpload,
  uploading,
  repayableByDP,
  previousStep,
  onReviewScreen,
}) => {
  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    setFieldTouched,
    isValid,
    dirty,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="cx-w-full cx-bg-background-default cx-my-10 cx-rounded-lg">
        {!onReviewScreen && (
          <div className="cx-mb-4">
            <InputField
              label="Company name"
              inputProps={{
                name: "companyName",
                value: values.companyName,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              tick={touched.companyName && !errors.companyName}
              error={touched.companyName ? errors.companyName : ""}
            />
          </div>
        )}

        <div className="cx-flex cx-gap-4 cx-w-full">
          <div className="cx-mb-4">
            <InputField
              label="Invoice no"
              inputProps={{
                name: "invoiceNo",
                value: values.invoiceNo,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              tick={touched.invoiceNo && !errors.invoiceNo}
              error={touched.invoiceNo ? errors.invoiceNo : ""}
            />
          </div>
          <div className="cx-mb-4">
            <InputField
              label="Invoice amount"
              inputProps={{
                name: "invoiceAmount",
                value: "AED " + values.invoiceAmount,
                readOnly: true,
                disabled: true,
              }}
              tick={!errors.invoiceAmount}
              error={touched.invoiceAmount ? errors.invoiceAmount : ""}
            />
          </div>
        </div>

        <div className="cx-flex cx-gap-4 cx-w-full">
          <div className="cx-mb-4">
            <DatePicker
              selectedDate={values.invoiceIssuedDate!}
              onChange={(date) => setFieldValue("invoiceIssuedDate", date)}
              onBlur={() => setFieldTouched("invoiceIssuedDate")}
              placeholderText="Invoice issued date"
              showDatePickerIcon={false}
              textAlignment="left"
              tick={touched.invoiceIssuedDate && !errors.invoiceIssuedDate}
              error={touched.invoiceIssuedDate ? errors.invoiceIssuedDate : ""}
            />
          </div>
          <div className="cx-mb-4">
            <DatePicker
              selectedDate={values.invoiceDueDate!}
              onChange={(date) => setFieldValue("invoiceDueDate", date)}
              onBlur={() => setFieldTouched("invoiceDueDate")}
              placeholderText="Invoice due date"
              showDatePickerIcon={false}
              textAlignment="left"
              tick={touched.invoiceDueDate && !errors.invoiceDueDate}
              error={touched.invoiceDueDate ? errors.invoiceDueDate : ""}
            />
          </div>
        </div>

        {!onReviewScreen &&
          values.invoices.map((invoice: File, index: number) => (
            <CardList
              key={index}
              variant={
                uploading
                  ? CardVariantType.UPLOADING
                  : invoice.name
                  ? CardVariantType.UPLOADED
                  : CardVariantType.UPLOAD
              }
              title="First invoice"
              description={invoice.name}
              inputProps={{ name: "invoice" }}
              icon={
                uploading ? (
                  <DocumentIcon width="100%" height="100%" />
                ) : (
                  <SuccessIcon
                    width="100%"
                    height="100%"
                    className="cx-text-background-success"
                  />
                )
              }
              onChange={async (file) => {
                if (!file) return;
                handleUpload(file);
              }}
              error={touched.invoices?.[index] && !!errors.invoices?.[index]}
              truncateDescriptionLength={35}
            />
          ))}
        {!onReviewScreen && !values.invoices?.length && (
          <Dropzone
            variant={uploading ? "uploading" : "simple"}
            extensions={["PDF only", "Maximum file size is 5 MB"]}
            options={{
              accept: { "application/pdf": [".pdf"] },
              maxFiles: 1,
              maxSize: 100 * 1024 * 1024,
            }}
            onDrop={async (e) => {
              const file = e[0];
              if (!file) return;
              handleUpload(file);
            }}
          />
        )}

        {!onReviewScreen && !repayableByDP && (
          <div className="cx-mt-4 cx-text-text-secondary cx-font-normal cx-text-center">
            If your application is successful we will provide an offer for a
            portion of the invoiced amount.
          </div>
        )}
      </div>

      {/* Next and Previous Buttons */}
      {!onReviewScreen && (
        <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
          <div className="cx-w-1/4">
            <StepPrevButton
              label={<ArrowLeftIcon />}
              type="button"
              onClick={previousStep}
            />
          </div>
          <div className="cx-w-3/4">
            <StepNextButton
              type="button"
              label="Next"
              disabled={
                uploading || !values.invoices?.length || !isValid || !dirty
              }
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </form>
  );
};

export default UploadInvoiceForm;
