import { useEffect, useMemo } from "react";
import {
  IFramePostMessageEvent,
  InitRequestEventData,
  InitResponseEventData,
  LoanApplicationCompletedRequestEventData,
} from "./types";
import { useSDKContext } from "../../contexts";
import useTimer from "../useTimer";

const IFRAME = window;
const PARENT = window.parent;

type Props = {};

export const useIFrameEvents = (props?: Props) => {
  const { origin, waitForPrefill, updateSDKContext, version } = useSDKContext();
  const {
    start: startTimer,
    stop: stopTimer,
    time: currentTime,
    isRunning: isTimerRunning,
  } = useTimer();

  const shouldMountIframe = useMemo(() => version === "2", [version]);

  useEffect(() => {
    if (!shouldMountIframe) return;

    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get("origin");
    if (origin) {
      updateSDKContext({ origin });
    }
  }, []);

  useEffect(() => {
    if (!shouldMountIframe) return;

    const handleEvent = async (event: MessageEvent) => {
      if (event.origin === origin) {
        try {
          const data: IFramePostMessageEvent<any> = JSON.parse(event.data);

          console.log("FROM PARENT:", data);
          switch (data.type) {
            case "INIT_RESPONSE":
              await handleInitResponse(data.data);
              break;
            case "LOAN_APPLICATION_STARTED_RESPONSE":
              await handleLoanApplicationStartedResponse(data.data);
              break;
            case "LOAN_APPLICATION_COMPLETED_RESPONSE":
              break;
            default:
              break;
          }
        } catch (err) {
          console.log(err);
        }
      }
    };

    if (origin) {
      IFRAME.addEventListener("message", handleEvent);
    }

    return () => {
      IFRAME.removeEventListener("message", handleEvent);
    };
  }, [origin]);

  useEffect(() => {
    if (isTimerRunning && currentTime) {
      if (currentTime >= 15) {
        stopTimer();
        updateSDKContext({ prefilling: false });
      }
    }
  }, [isTimerRunning, currentTime]);

  const handleInitResponse = async (data: InitResponseEventData) => {
    if (!shouldMountIframe) return;

    if (data.clientId && data.sessionToken) {
      updateSDKContext({
        clientId: data.clientId,
        sessionToken: data.sessionToken,
        ...(!!data.options && { options: data.options }),
        ...(!!data.waitForPrefill && {
          waitForPrefill: data.waitForPrefill,
        }),
      });
    }
  };

  const handleLoanApplicationStartedResponse = async (
    data: LoanApplicationCompletedRequestEventData
  ) => {
    if (!shouldMountIframe) return;

    if (isTimerRunning) {
      stopTimer();
    }

    updateSDKContext({ prefilling: false, prefillingCompleted: true });
  };

  const postInitEvent = () => {
    if (!shouldMountIframe) return;

    const event: IFramePostMessageEvent<InitRequestEventData> = {
      type: "INIT_REQUEST",
      data: {
        origin: window.origin,
      },
    };

    PARENT.postMessage(JSON.stringify(event), origin);
  };

  const postLoanApplicationStartedEvent = (
    data: LoanApplicationCompletedRequestEventData
  ) => {
    if (!shouldMountIframe) return;

    if (waitForPrefill) {
      const event: IFramePostMessageEvent<LoanApplicationCompletedRequestEventData> =
        {
          type: "LOAN_APPLICATION_STARTED_REQUEST",
          data: {
            loanApplicationId: data.loanApplicationId,
          },
        };

      PARENT.postMessage(JSON.stringify(event), origin);
      updateSDKContext({ prefilling: true });
      startTimer();
    }
  };

  const postLoanApplicationCompletedEvent = (
    data: LoanApplicationCompletedRequestEventData
  ) => {
    if (!shouldMountIframe) return;

    const event: IFramePostMessageEvent<LoanApplicationCompletedRequestEventData> =
      {
        type: "LOAN_APPLICATION_COMPLETED_REQUEST",
        data: {
          loanApplicationId: data.loanApplicationId,
        },
      };

    PARENT.postMessage(JSON.stringify(event), origin);
  };

  return {
    postInitEvent,
    postLoanApplicationStartedEvent,
    postLoanApplicationCompletedEvent,
  };
};
