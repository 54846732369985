type Props = {
  text: string;
};

const Footer: React.FC<Props> = ({ text }) => {
  return (
    <div className="cx-max-w-[626px] cx-text-center cx-text-text-tertiary cx-text-xs cx-m-auto">
      {text}
    </div>
  );
};

export default Footer;
